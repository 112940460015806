<template></template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "http-common-mixin",
  data() {
    return {};
  },
  methods: {
    ...mapGetters(["getToken"]),
    onFinal() {
      // console.log("commonFinal");
    },
    onError(error) {
      console.log("error:", error);
    },
    onSuccess(data) {
      console.log(data);
    },
    doHttpGet(url, params, onSuccess = null, onError = null, onFinal = null) {
      const currentObj = this;
      const token = this.getToken();
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      config.params = params;
      this.axios
        .get(url, config)
        .then((response) => {
          if (onSuccess != null) {
            onSuccess(response.data);
          } else {
            currentObj.onSuccess(response.data);
          }
        })
        .catch((error) => {
          if (onError != null) {
            onError(error);
          } else {
            currentObj.onError(error);
          }
        })
        .finally(() => {
          if (onFinal != null) {
            onFinal();
          } else {
            currentObj.onFinal();
          }
        });
    },
    doHttpPost(url, data, onSuccess = null, onError = null, onFinal = null) {
      const currentObj = this;
      const token = this.getToken();
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      this.axios
        .post(url, data, config)
        .then((response) => {
          if (onSuccess != null) {
            onSuccess(response.data);
          } else {
            currentObj.onSuccess(response.data);
          }
        })
        .catch((error) => {
          if (onError != null) {
            onError(error);
          } else {
            currentObj.onError(error);
          }
        })
        .finally(() => {
          if (onFinal != null) {
            onFinal();
          } else {
            currentObj.onFinal();
          }
        });
    },
    doHttpPut(url, data, onSuccess = null, onError = null, onFinal = null) {
      const currentObj = this;
      const token = this.getToken();
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      this.axios
        .put(url, data, config)
        .then((response) => {
          if (onSuccess != null) {
            onSuccess(response.data);
          } else {
            currentObj.onSuccess(response.data);
          }
        })
        .catch((error) => {
          if (onError != null) {
            onError(error);
          } else {
            currentObj.onError(error);
          }
        })
        .finally(() => {
          if (onFinal != null) {
            onFinal();
          } else {
            currentObj.onFinal();
          }
        });
    },
    doHttpDeleteDataData(
      url,
      data,
      onSuccess = null,
      onError = null,
      onFinal = null
    ) {
      const currentObj = this;
      const token = this.getToken();
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };
      this.axios
        .delete(url, config)
        .then((response) => {
          if (onSuccess != null) {
            onSuccess(response.data);
          } else {
            currentObj.onSuccess(response.data);
          }
        })
        .catch((error) => {
          if (onError != null) {
            onError(error);
          } else {
            currentObj.onError(error);
          }
        })
        .finally(() => {
          if (onFinal != null) {
            onFinal();
          } else {
            currentObj.onFinal();
          }
        });
    },
    doHttpDelete(url, onSuccess = null, onError = null, onFinal = null) {
      const currentObj = this;
      const token = this.getToken();
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      this.axios
        .delete(url, config)
        .then((response) => {
          if (onSuccess != null) {
            onSuccess(response.data);
          } else {
            currentObj.onSuccess(response.data);
          }
        })
        .catch((error) => {
          if (onError != null) {
            onError(error);
          } else {
            currentObj.onError(error);
          }
        })
        .finally(() => {
          if (onFinal != null) {
            onFinal();
          } else {
            currentObj.onFinal();
          }
        });
    },
    // download file:
    forceFileDownload(response, filename) {
      console.log("forceFileDownload:", filename);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
    },
    doDownloadFile(url, params, filename, callback = null) {
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
        responseType: "blob",
        params: params,
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          alert("檔案不存在");
        })
        .finally(() => {
          if (callback != null) {
            callback();
          }
        });
    },
  },
};
</script>
