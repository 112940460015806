<template>
  <v-container fluid class="py-2">
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <v-dialog v-model="proxy_dialog" width="60%" class="pa-0 ma-0">
      <v-card class="ma-0 pa-0">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <label class="text-sm text-typo font-weight-bolder"
                >Proxy帳號</label
              >
              <v-text-field
                v-model="proxy_account"
                outlined
                dense
                hide-details
                class="text-typo font-size-input border rounded-0 mt-2"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="text-sm text-typo font-weight-bolder"
                >Proxy描述</label
              >
              <v-text-field
                v-model="proxy_description"
                outlined
                dense
                hide-details
                class="text-typo font-size-input border rounded-0 mt-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mx-4 my-0 py-0">
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-secondary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="closeDialog()"
            >取消</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            @click="onProxyUpdate()"
            color="#5e72e4"
            >更新</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="import_dialog" width="60%" class="pa-0 ma-0">
      <v-card class="ma-0 pa-0">
        <v-row class="mx-4 pt-2">
          <v-col cols="4">
            <label class="text-sm text-typo font-weight-bolder">覆蓋</label>
            <v-text-field
              v-model="edit_item.page"
              outlined
              dense
              hide-details
              class="text-typo font-size-input border rounded-0 mt-2"
            ></v-text-field>
          </v-col>
          <v-col cols="8">
            <label class="text-sm text-typo font-weight-bolder ms-1"
              >檔案</label
            >
            <v-file-input
              accept=".xlsx,.xls"
              label="上傳檔案"
              v-model="file"
              @change="checkPage()"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-card-actions class="mx-4 my-0 py-0">
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-secondary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="closeDialog()"
            >取消</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="uploadImageExcel()"
            >確定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="card-shadow border-radius-xl mt-2">
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-btn
              elevation="0"
              :ripple="false"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-primary
                py-2
                px-6
                me-2
                mt-6
                mb-2
              "
              color="#5e72e4"
              @click="newItem()"
              >新增</v-btn
            >
            <v-btn
              elevation="0"
              :ripple="false"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
              "
              color="#5e72e4"
              @click="onProxyEdit()"
              >Proxy設定</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text class="px-4 py-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
          :options.sync="options"
          :server-items-length="items_count"
          :loading="loading"
        >
          <template v-slot:loading>
            {{ $t("Loading...") }}
          </template>
          <template v-slot:item.create_time="{ item }">
            {{ item.create_time | dateFormatDash }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ item.status | getText(ocr_status_enum_str) }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ item.type | getText(image_source_enum_str) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  large
                  @click="downloadExcel(item)"
                  >mdi-download</v-icon
                >
              </template>
              <span>下載</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  large
                  color="red"
                  @click="onEdit(item)"
                  >mdi-upload</v-icon
                >
              </template>
              <span>上傳更新</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  large
                  color="green"
                  @click="onExport(item)"
                  >mdi-export</v-icon
                >
              </template>
              <span>匯出</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  large
                  color="blue"
                  @click="onDelete(item)"
                  >mdi-close</v-icon
                >
              </template>
              <span>刪除</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="getPageCount()"
              circle
              :total-visible="15"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import {
  ocr_status_enum,
  ocr_status_enum_str,
  image_source_enum_str,
} from "@/definitions.js";
import { mapGetters } from "vuex";
import CommonUtility from "@/util/CommonUtility.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
export default {
  name: "image-excel-view",
  data(vm) {
    return {
      ocr_status_enum: ocr_status_enum,
      ocr_status_enum_str: ocr_status_enum_str,
      image_source_enum_str: image_source_enum_str,
      page: 1,
      pageCount: 0,
      items_count: 0,
      itemsPerPage: 10,
      options: {},
      items: [],
      loading: false,
      headers: [
        // {
        //   text: "ID",
        //   value: "id",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "名稱",
          value: "page",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },

        {
          text: "Type",
          value: "type",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "create time",
          value: "create_time",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "right",
          class: "text-secondary font-weight-bolder opacity-7",
          sortable: false,
        },
      ],
      edit_item: {},
      import_dialog: false,
      proxy_dialog: false,
      file: null,
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      proxy_account: null,
      proxy_description: null,
    };
  },
  components: {
    CommonConfirm,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
  watch: {
    options() {
      console.log("options changed");
      this.getData();
    },
  },
  mixins: [HttpCommonMixin],
  methods: {
    ...mapGetters(["getToken"]),
    closeDialog() {
      this.import_dialog = false;
      this.proxy_dialog = false;
      this.file = null;
      this.edit_item = {};
    },

    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    onFinal() {
      this.loading = false;
    },
    getData() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/image-excel`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };

      this.loading = true;
      this.doHttpGet(url, params, this.setResult, null, this.onFinal);
    },
    setResult(data) {
      console.log(data.items);
      this.items_count = data.total;
      this.items = data.items;
      this.loading = false;
    },
    downloadExcel(item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/image-excel-detail/${item.id}`;
      let filename = `${item.page}.xlsx`;
      this.doDownloadFile(url, {}, filename);
    },
    setDownloadResult(data) {
      console.log(data);
    },
    checkPage() {
      // edit_item.page
      if (this.edit_item != null && this.edit_item.page == null) {
        if (this.file != null && this.file.name != null) {
          // remove suffix of file name
          let filename = this.file.name;
          let index = filename.lastIndexOf(".");
          if (index > 0) {
            filename = filename.substring(0, index);
          }
          console.log("this.filename", this.file.name, " ", filename);
          this.edit_item.page = filename;
        }
      }
    },
    newItem() {
      this.edit_item = {};
      this.import_dialog = true;
    },
    onEdit(item) {
      this.edit_item = item;
      this.import_dialog = true;
    },
    onDeleteConfirm() {
      let item = this.edit_item;
      this.confirm.dialog = false;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/image-excel/${item.id}`;
      this.doHttpDelete(url, {}, this.setDeleteResult, null, this.onFinal);
    },
    setDeleteResult(data) {
      console.log(data);
      alert("刪除成功");
      this.getData();
    },
    onDelete(item) {
      this.edit_item = item;
      this.confirm = {
        dialog: true,
        title: "刪除",
        message: "確定要刪除嗎?",
        function: this.onDeleteConfirm,
      };
    },
    uploadImageExcel() {
      let item = this.edit_item;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/image-excel`;
      const formData = new FormData();
      formData.append("page", item.page);
      formData.append("excel", this.file);
      this.doHttpPost(
        url,
        formData,
        this.setUploadResult,
        this.setUploadFail,
        this.onFinal
      );
      this.closeDialog();
    },
    setUploadFail(data) {
      console.log(data);
      alert("更新失敗 檢查檔案格式或名稱");
    },
    setUploadResult(data) {
      console.log(data);
      alert("更新成功");
    },
    onProxyEdit() {
      // /api/v1/system-config/br-proxy
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/system-config/br-proxy`;
      this.doHttpGet(url, {}, this.setProxyResult, null, this.onFinal);
    },
    setProxyResult(data) {
      console.log(data);
      this.proxy_account = data.value;
      this.proxy_description = data.description;
      this.proxy_dialog = true;
    },
    onProxyUpdate() {
      // /api/v1/system-config/br-proxy
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/system-config/br-proxy`;
      const data = {
        value: this.proxy_account,
        description: this.proxy_description,
      };
      this.doHttpPut(url, data, this.setProxyUpdateResult, null, this.onFinal);
    },
    setProxyUpdateResult(data) {
      console.log(data);
      alert("更新成功");
      this.closeDialog();
    },
    onExport(item) {
      console.log("onExport", item.id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/${item.id}/image-excel-import`;
      let filename = `${item.page}.xlsx`;
      this.doDownloadFile(url, {}, filename);
      // this.doHttpGet(url, {}, this.setExportResult, null, this.onFinal);
    },
    setExportResult(data) {
      console.log(data);
      // alert("匯出成功");
    },
  },
};
</script>
